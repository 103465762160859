var AD_REFERENCE_URL_PREFIX = "/adRef/";
var BASE_FILE_IN_URL ="/try_final_cordova.html";
var scriptUniversalLinks = {

  execute: function() {
    this.bindEvents();
  },
  
  bindEvents: function() {
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
  },
  

  onAppLaunchWithAdReference : function(eventData){
    var adReference =  null;
    var path = eventData.path;
    if(path && path.indexOf(AD_REFERENCE_URL_PREFIX) == 0){
      adReference = path.substr(AD_REFERENCE_URL_PREFIX.length);

    }
    if(window.trebble && adReference){
      window.trebble.adReference = adReference;
    }else{
      if(!window._trebblePropsBeforeInit){
        window._trebblePropsBeforeInit = {};
      }
      window._trebblePropsBeforeInit.adReference = adReference;
    }

  },

  onAppLaunchedWithPlayingSpecificTrebbleAndShowPlayerPage : function(eventData){
    this._launchAppByUsingPathAsHash(eventData);
  },

  onAppLaunchedWithPlayUserFeedAndShowPlayerPage : function(eventData){
    this._launchAppByUsingPathAsHash(eventData);
  },

  _launchAppByUsingPathAsHash : function(eventData){
    var path = eventData.path;
    ///var convertedHash = eventData.path.substr(1);
    //window.location.replace(window.location.href.substr(0,window.location.href.indexOf("#")) +"#"+convertedHash.replace(/\//g , "\\/"));
    //window.location.replace(location.href.substr(0 , location.href.indexOf(BASE_FILE_IN_URL) + BASE_FILE_IN_URL.length) + path);
    //window.history.pushState({}, document.title, location.pathname.substr(0 , location.pathname.indexOf(BASE_FILE_IN_URL) + BASE_FILE_IN_URL.length) + path);
    if(window.trebble && window.trebble.routerReady){
      Backbone.history.navigate(path,{trigger:true});
    }else{
      document.addEventListener('routerReady', function(){ 
        Backbone.history.navigate(path,{trigger:true})
      }, false);
    }
  },

  onAppLaunchedWithSpecificTrebblePage  : function(eventData){
    this._launchAppByUsingPathAsHash(eventData);
  },

  onAppLaunchedWithSpecificUserPage  : function(eventData){
    this._launchAppByUsingPathAsHash(eventData);
  },

  onAppLaunchedWithExplorePage  : function(eventData){
    this._launchAppByUsingPathAsHash(eventData);
  },

  onAppLaunchedWithHomePage  : function(eventData){
    this._launchAppByUsingPathAsHash(eventData);
  },


  onAppLaunchedAppFromLink : function(){

  },

  onDeviceReady: function() {
    if('universalLinks' in window){
      universalLinks.subscribe('appLaunchedWithAdReference', this.onAppLaunchWithAdReference.bind(this));
      universalLinks.subscribe('appLaunchedAppFromLink', this.onAppLaunchedAppFromLink.bind(this));
      universalLinks.subscribe('appLaunchedWithPlayingSpecificTrebbleAndShowPlayerPage', this.onAppLaunchedWithPlayingSpecificTrebbleAndShowPlayerPage.bind(this));
      universalLinks.subscribe('appLaunchedWithPlayUserFeedAndShowPlayerPage', this.onAppLaunchedWithPlayUserFeedAndShowPlayerPage.bind(this));
      universalLinks.subscribe('appLaunchedWithSpecificTrebblePage', this.onAppLaunchedWithSpecificTrebblePage.bind(this));
      universalLinks.subscribe('appLaunchedWithSpecificProfilePage', this.onAppLaunchedWithSpecificTrebblePage.bind(this));
      universalLinks.subscribe('appLaunchedWithSpecificUserPage', this.onAppLaunchedWithSpecificUserPage.bind(this));
      universalLinks.subscribe('appLaunchedWithExplorePage', this.onAppLaunchedWithExplorePage.bind(this));
      universalLinks.subscribe('appLaunchedWithHomePage', this.onAppLaunchedWithHomePage.bind(this));
    }
    
  },
  
};

scriptUniversalLinks.execute();
